/** 10xtech **/


*{
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
html, body {
  height: 100%;
  box-sizing: border-box;
  margin: 0;
}

.layout-sidebar{
    width: 250px;
}

.layout-body{
  margin-top: 1em;
  display: flex;
  gap: 1em;
  flex-flow: row;
}
.layout-body-left{
  display: flex;
  min-width: 200px;
  max-width: 200px;
}
.layout-body-right{
  flex-grow: 2;
}

.layout-secondary-bar{
  align-items: center;
}
.layout-secondary-bar .menu-list{
  display: flex;
  gap: 1em;
  padding:.2em;
}
.layout-secondary-bar .menu-list-item{
  display: grid;
  margin:0;
  max-width: 80px;
  text-align: center;
}

.layout-secondary-bar .mat-mdc-list-item, .mat-mdc-list-option{
  width: auto;
}


.mat-toolbar{
  background-color: #fff;
  color:#6e6e6e;
}
.mat-mdc-list-base{
  --mdc-list-list-item-trailing-icon-color: #3f51b5;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
   background-color: #fff;
}
.mdc-list-item--with-leading-icon .mdc-list-item__start{
  color :#3f51b5 !important;
}
.d-flex{
  display: flex;
}

.justify-content-center{
  justify-content: center;
}
.align-item-center{
  align-items: center;
}

.text-success{
  color : #28a745 !important;
}

.text-danger{
  color : #dc3545!important;
}

.text-warning{
  color: #ffc107!important
}

.clearable{
  display: inline-flex;
}

.card-list{
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  margin: auto;
}

.card-list .card-list-item{
  max-width: 300px;
}


@media screen and (max-width:640px) {

.card-list{
    flex-flow: column wrap;
  }
}
